/* Copyright (C) Okahu Inc 2023-2024. All rights reserved. */

'use client';

import { useEffect, useState } from 'react';
import { request } from '@/services/request';

const useApps = () => {
  const [data, setData] = useState<any>();
  const [error, setError] = useState<any>();
  const [loading, setLoading] = useState(true);

  const fetchApps = async () => {
    setLoading(true);
    try {
      const data = await request.get({ endpoint: 'apps' });
      setData(data[0]);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchApps();
  }, []);

  return { data, error, loading, fetchApps };
};

export default useApps;
