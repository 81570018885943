/* Copyright (C) Okahu Inc 2023-2024. All rights reserved.*/

'use client';

import { useEffect } from 'react';
import { useRouter } from 'next/navigation';
import { useLanguage } from '@/providers/LanguageProvider';
import { CheckIcon } from 'lucide-react';

export const AccountCreated = () => {
  const router = useRouter();
  const { locale, messages } = useLanguage();

  useEffect(() => {
    setTimeout(() => {
      // Redirecting to /apps
      router.push(`/${locale}/apps`);
    }, 2000);
  }, [router]);

  return (
    <div className="flex h-screen flex-col items-center justify-center gap-y-4">
      <span className="flex size-[72px] items-center justify-center rounded-full bg-green-dark">
        <CheckIcon className="size-10" />
      </span>
      <div className="text-center">
        <h3 className="text-h3 text-primary">
          {messages?.Account?.acc_created_successfully}
        </h3>
        <p className="text-body-sm text-form-input-disabled">
          {messages?.Account?.redirect_to_app}..
        </p>
      </div>
    </div>
  );
};
