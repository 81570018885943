/* Copyright (C) Okahu Inc 2023-2024. All rights reserved.*/
'use client';

import Image from 'next/image';
import { useLanguage } from '@/providers/LanguageProvider';

export const AccountError = () => {
  const { messages } = useLanguage();
  return (
    <div className="flex h-screen flex-col items-center justify-center gap-y-8">
      <Image
        src="/logos/full-color-reversed/medium.svg"
        width={100}
        height={100}
        alt="Logo"
        className="mx-5 mt-4"
      />
      <div className="space-y-4">
        <div className="flex justify-center gap-x-3 rounded-lg border border-[#F26C74]/[.32] bg-notification-error-dark/[.8] p-6">
          <Image
            src="/logos/errors.svg"
            className="size-8"
            width={100}
            height={100}
            alt="Logo"
          />
          <div className="space-y-0.5 text-notification-error-dark">
            <h3 className="text-h3">
              {' '}
              {messages?.Account?.unable_to_create_your_account}
            </h3>

            <p className="text-center text-body">
              {messages?.Account?.please}{' '}
              <a
                href="/api/auth/logout"
                className="text-link-active"
                rel="noopener noreferrer"
              >
                {messages?.Account?.try_again}
              </a>{' '}
              {messages?.Account?.if_the_problem_persists_please}{' '}
              <a
                href="mailto:cx@okahu.ai"
                className="text-link-active"
                rel="noopener noreferrer"
              >
                {messages?.Account?.contact_us}
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
