/* Copyright (C) Okahu Inc 2023-2024. All rights reserved. */

'use client';

import { useEffect, useState } from 'react';
import Image from 'next/image';
import Link from 'next/link';
import { useParams, useRouter } from 'next/navigation';
import { useLanguage } from '@/providers/LanguageProvider';
import { BadgeCheck } from 'lucide-react';

interface IAccessToken {
  sub: string;
  aud: Array<string>;
  exp: string;
  org_id: string;
}

export const InfoContainer = () => {
  const router = useRouter();
  const params = useParams();
  const [showSignUpSuccess, setShowSignupSuccess] = useState(false);
  const [signUpCountDown, setSignUpCountDown] = useState<number>(10);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const { messages } = useLanguage();
  const langData = messages?.InfoContainer;

  useEffect(() => {
    let interval: any;
    const hash = window.location.hash;
    setSignUpCountDown(10);
    if (hash) {
      try {
        const accessToken = extractAccessToken(hash);
        if (
          accessToken &&
          accessToken.aud &&
          accessToken.exp &&
          accessToken.sub
        ) {
          setShowSignupSuccess(true);
          interval = startCountDown();
        }
      } catch {
        setShowSignupSuccess(false);
      }
    }
    setIsLoading(false);
    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [params]);

  const startCountDown = () => {
    const interval = setInterval(() => {
      setSignUpCountDown((prev) => {
        if (prev <= 1) {
          router.push('/apps');
          clearInterval(interval);
        }
        return prev - 1;
      });
    }, 1000);
    return interval;
  };

  const extractAccessToken = (hash: string) => {
    const segments = hash.split('&');
    const fSegment = segments[0];
    const accessToken = fSegment.replace('#access_token=', '');

    return parseJwt(accessToken);
  };

  const parseJwt = (token: string) => {
    if (!token) {
      return;
    }
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace('-', '+').replace('_', '/');
    const tokenObj: IAccessToken = JSON.parse(window.atob(base64));
    return tokenObj;
  };

  return (
    <>
      {!isLoading && (
        <>
          {!showSignUpSuccess ? (
            <div className="flex flex-col items-center rounded-md border border-form-control-border-focus px-20 py-5 shadow-[0px_0px_20px_0px_#00000080] shadow-form-control-border-focus">
              <h1 className="text-7xl font-bold">Okahu</h1>
              <p className="">{langData?.ai_observability_as_a_service}</p>
              <Link
                href={'/api/auth/login'}
                className="mt-8 rounded bg-fill-button-rest px-4 py-1"
                data-testid="get-started-link"
              >
                {langData?.get_started}
              </Link>
            </div>
          ) : (
            <div className="flex min-h-screen flex-col items-center justify-center gap-y-6">
              {/* logo */}
              <Link href={'/'}>
                <Image
                  src="/logos/full-color-reversed/medium.svg"
                  width={100}
                  height={100}
                  alt="Logo"
                />
              </Link>
              {/* error message */}
              <div className="flex max-w-xl gap-x-3 rounded-lg border border-info-light bg-[#F26C7414] p-6 text-notification-success-dark">
                <BadgeCheck size={32} />
                <div className="space-y-1">
                  <h3 className="text-h3 font-medium">
                    {langData?.signup_successful}
                  </h3>
                  <p className="leading-[21.28px text-[14px] font-normal">
                    {messages?.General?.success}:{' '}
                    <span className="first-letter:capitalize">
                      {langData?.you_have_successfully_signed_up}
                    </span>
                  </p>
                </div>
              </div>
              {/* Redirection message */}
              <div className="leading-[21.28px text-[14px] font-normal">
                {langData?.redirecting_to_login_page_in} {signUpCountDown}{' '}
                {langData?.seconds_or}{' '}
                <Link href={`/apps`} className="text-link-active">
                  {langData?.click_here}
                </Link>{' '}
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
};
