/* Copyright (C) Okahu Inc 2023-2024. All rights reserved.*/

'use client';

import { useEffect, useState } from 'react';
import { useLanguage } from '@/providers/LanguageProvider';
import { request } from '@/services/request';
import { useUser } from '@auth0/nextjs-auth0/client';
import { AppList } from 'types/app-list';

import BasicLoader from '../spinner/basic-loader';
import { AccountCreated } from './account-created';
import { AccountError } from './account-error';

export const Provisioning = () => {
  const [appData, setAppData] = useState<AppList>([]);
  const [error, setError] = useState(false);
  const { messages } = useLanguage();

  const { user } = useUser();

  useEffect(() => {
    request
      // provisioning the tenant
      .post({ endpoint: 'tenant' }, { display_name: user?.email }, true)
      // after successful tenant provisioning, make a call to access-token endpoint
      .then(() => fetch('/api/access-token?grant=true'))
      .then((res) => {
        if (!res.ok) {
          throw new Error(`HTTP error! status: ${res.status}`);
        }
      })
      // make a call to apps endpoint
      .then(async () => {
        const data = await request.get({ endpoint: 'apps' });
        return setAppData(data[0].apps);
      })
      .catch((err) => {
        console.error('Error:', err);
        // Handle errors like 400 status code
        if (err?.status === 400) {
          setError(true);
        }
      });
  }, []);

  if (appData.length) {
    return <AccountCreated />;
  }

  if (error) {
    return <AccountError />;
  }

  return (
    <div className="flex h-screen flex-col items-center justify-center gap-y-6">
      <BasicLoader className="size-10" />
      <div className="space-y-2 text-center">
        <p className="text-body text-primary">
          {messages?.Account?.provisioning_your_account}
        </p>
        <p className="text-body-sm text-form-input-disabled">
          {messages?.General?.please_wait}..
        </p>
      </div>
    </div>
  );
};
